import React from 'react';
import 'assets/styles/app.scss';

const NotFoundPage = () => (
  <div>
    <h1>404</h1>
  </div>
);

export default NotFoundPage;
